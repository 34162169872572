import {
  Credentials,
  GeneralResponseModel,
  ICreateNewPasswordRequest,
  ILoginCredentials,
  ILoginRequests,
  ISignUpRequest,
  IUser,
} from 'shared/types';
import { generateSuccessToast, getDeviceName } from 'shared/utils';

import { API_VI, instance } from './api';

const loginService = async (
  { email, password }: ILoginRequests,
  noSuccessToast = false
): Promise<Credentials> => {
  return await instance
    .post(
      API_VI + '/auth/login',
      { email, password, device_name: getDeviceName() },
      { headers: { 'No-Success-Toast': noSuccessToast } }
    )
    .then((response) => {
      if (response.data?.status && !response.config.headers['No-Success-Toast'])
        generateSuccessToast('Successfully logged in!');
      return response.data.data;
    });
};

const impersonateService = async ({ id }: { id: number }): Promise<Credentials> => {
  return await instance.post(API_VI + `/auth/impersonate/${id}`).then((response) => {
    if (response.data?.status) generateSuccessToast('Successfully logged in as user ' + id);
    return response.data.data;
  });
};

const getMyProfileService = async (): Promise<IUser> => {
  return await instance.get(API_VI + '/auth/me').then((response) => {
    return response.data.data;
  });
};

const signUpService = async (
  data: ISignUpRequest,
  noSuccessToast: boolean = false
): Promise<Credentials> => {
  return await instance
    .post(
      API_VI + '/auth/register',
      { ...data, device_name: getDeviceName() },
      { headers: { 'No-Success-Toast': noSuccessToast } }
    )
    .then((response) => {
      if (response.data?.status && !response.config.headers['No-Success-Toast'])
        generateSuccessToast(response.data?.message);
      return response.data.data;
    });
};

const passwordRecoveryService = async (email: string): Promise<GeneralResponseModel<unknown>> => {
  return await instance.post(API_VI + '/auth/password-recovery', { email }).then((response) => {
    if (response.data?.message) generateSuccessToast(response.data?.message);
    return response.data;
  });
};

const createNewPasswordRecoveryService = async (
  data: ICreateNewPasswordRequest
): Promise<GeneralResponseModel<unknown>> => {
  return await instance.patch(API_VI + '/auth/password-restore', { ...data }).then((response) => {
    if (response.data?.message) generateSuccessToast(response.data?.message);
    return response.data;
  });
};

const logOutService = async (): Promise<unknown> => {
  return await instance.post(API_VI + '/auth/logout').then((response) => {
    if (response.data?.status) generateSuccessToast('Successfully logged out!');
    return response.data.data;
  });
};

const checkEmailIsFreeService = async (email: string): Promise<boolean> => {
  return await instance
    .post(API_VI + '/checks/users/unique-email', { email }, { headers: { 'No-Toast': true } })
    .then((response) => {
      return response.data.status && response.data.data.is_unique;
    });
};

export {
  impersonateService,
  loginService,
  logOutService,
  signUpService,
  passwordRecoveryService,
  createNewPasswordRecoveryService,
  getMyProfileService,
  checkEmailIsFreeService,
};
