import * as React from 'react';

import { IInputProps } from 'shared/types';
import { cn } from 'shared/utils';

import { Label } from '../Label';

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      className,
      autoFocus,
      type,
      error,
      titleClassName,
      errorClassName,
      wrapClassName,
      suffix,
      labelSize = 'sm',
      title,
      ...props
    },
    ref
  ) => {
    return (
      <div className={cn(`grid w-full items-center gap-1 relative`, wrapClassName)}>
        {title && (
          <Label
            className={cn(
              'block mb-2 font-normal text-gray-900 dark:text-white ' +
                (labelSize === 'sm'
                  ? 'text-[12px]'
                  : labelSize === 'md'
                    ? 'text-[15px]'
                    : 'text-[17px]'),
              titleClassName
            )}
          >
            {title}
          </Label>
        )}
        <input
          autoFocus={autoFocus}
          type={type}
          className={cn(
            'bg-gray-50 rounded-lg border ' +
              `${error?.message ? 'border-rose-500' : 'border-gray-300 dark:border-gray-300'} ` +
              'text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ' +
              'disabled:cursor-not-allowed disabled:bg-bgGrey',
            className
          )}
          ref={ref}
          {...props}
        />
        {suffix && (
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[calc(theme(spacing[3.5])-1px)] text-base/6 text-zinc-500 sm:pr-[calc(theme(spacing[3])-1px)] sm:text-sm/6">
            {suffix}
          </span>
        )}
        {error?.message && (
          <span
            className={cn(
              'text-[12px] mt-[2px] ml-[2px] inset-y-full left-2 text-rose-500',
              errorClassName
            )}
          >
            {error?.message}
          </span>
        )}
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
